<template>
  <v-container class="ma-0 pa-0">
    <v-row>
      <v-col cols="12">
        <v-card
          class="rounded-xl"
          color="blue-grey lighten-5"
        >
          <miagenda />
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="12"
        md="12"
        sm="12"
      >
        <v-card
          dark
          color="transparent"
          class="rounded-xl"
        >
          <v-parallax
            height="120"
            dark
            src="https://cdn.vuetifyjs.com/images/backgrounds/vbanner.jpg"
          >
            <v-row>
              <v-col
                cols="12"
                sm="9"
              >
                <v-list-item three-line>
                  <v-list-item-content>
                    <v-list-item-title class="text-h4 mb-1">
                      Hola {{ getdatosUsuario.nomuser }} 👋!
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      Este es el panel donde podras encontrar todo lo
                      relacionado con tu dia a dia.
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-col>
              <v-col
                cols="12"
                sm="3"
              >
                <v-img
                  src="@/assets/soporte1.png"
                  contain
                  height="100"
                  class="mt-2 mr-5"
                />
              </v-col>
            </v-row>
          </v-parallax>
        </v-card>
      </v-col>
    </v-row>

    <v-row class="mt-1">
      <v-col
        v-for="card in cards"
        :key="card.icon"
        cols="12"
        sm="3"
        md="3"
      >
        <v-card
          elevation="3"
          max-height="245"
          class="rounded-xl"
          :color="card.color"
          dark
        >
          <v-list-item>
            <v-list-item-content>
              <v-list-item-subtitle>
                {{ card.subtitulo }}
              </v-list-item-subtitle>
              <v-list-item-title>
                <h1>{{ card.cantidad }}</h1>
              </v-list-item-title>
              <v-list-item-subtitle>
                <h4>{{ card.modulo }}</h4>
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <v-list-item-icon>
                <v-btn
                  :to="card.to"
                  text
                >
                  <v-icon x-large>
                    {{ card.icono }}
                  </v-icon>
                </v-btn>
              </v-list-item-icon>
            </v-list-item-action>
          </v-list-item>
          <v-row justify="end">
            <v-btn
              text
              class="mr-1"
              :to="card.to"
            >
              <v-icon>
                mdi-pencil
              </v-icon>
            </v-btn>
          </v-row>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col
        v-for="contador in contadores"
        :key="contador.icon"
        cols="12"
        sm="3"
        md="3"
      >
        <v-card
          elevation="3"
          max-height="230"
          class="rounded-xl"
          color="blue-grey lighten-5"
        >
          <v-toolbar
            dense
            class="mr-15"
            shaped
            :color="contador.color"
            height="45"
            dark
          >
            {{ contador.modulo }}
            <v-spacer />
            <v-icon>
              {{ contador.icono }}
            </v-icon>
          </v-toolbar>

          <v-card-subtitle>
            {{ contador.subtitulo }}
          </v-card-subtitle>

          <v-row
            justify="center"
            class=""
          >
            <v-btn
              :to="contador.to"
              elevation="0"
              dark
              x-large
              fab
              :color="contador.color"
            >
              <h2>{{ contador.cantidad }}</h2>
            </v-btn>
          </v-row>
          <v-row
            justify="center"
            class="mb-1"
          >
            <h3>{{ contador.modulo }}</h3>
          </v-row>

          <v-divider class="mb-1" />
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
// import miscitas from '@/views/crm/citas/miscitas'
// import misproyectos from '@/views/crm/proyectos/misproyectos'
// import mistareas from '@/views/crm/tareas/mistareas'
import miagenda from '@/views/crm/agenda/miagenda'
// import misactividades from '@/views/crm/actividades/misactividades'
// import misoportunidades from '@/views/crm/oportunidades/misoportunidades'

export default {
  components: {
    // miscitas,
    // misproyectos,
    // mistareas,
    miagenda
    // misactividades,
    // misoportunidades
  },
  data: () => ({
    veract: false,
    vercita: true,
    veroport: false,
    verproy: false,
    vertarea: false,
    type: 'day',
    cards: [],
    contadores: []
  }),
  computed: {
    ...mapGetters('login', ['getdatosUsuario', 'getLogeado']),

    esUsuario() {
      return this.getLogeado && this.getdatosUsuario.nivel == 'USER'
    },

    esAsesor() {
      return this.getLogeado && this.getdatosUsuario.nivel == 'ADMIN'
    },

    cal() {
      return this.ready ? this.$refs.calendar : null
    }
  },

  created() {
    ;(this.cards = [
      {
        modulo: 'Actividades',
        cantidad: '3',
        to: '/catactividades',
        color: 'red',
        subtitulo: 'Realiza tus',
        icono: 'mdi-notebook-edit',
        src: 'https://i.ytimg.com/vi/d9H-v5ZIq4U/maxresdefault.jpg'
      },
      {
        modulo: 'Oportunidades',
        cantidad: '32',
        to: '/catoportunidades',
        color: 'green',
        subtitulo: 'Tienes:',
        icono: 'mdi-store',
        src: 'https://i.ytimg.com/vi/d9H-v5ZIq4U/maxresdefault.jpg'
      },
      {
        modulo: 'Citas',
        cantidad: '2',
        to: '/catcitas',
        color: 'blue',
        subtitulo: 'El dia de hoy tienes:',
        icono: 'mdi-account-group',
        src: 'https://i.ytimg.com/vi/d9H-v5ZIq4U/maxresdefault.jpg'
      },
      {
        modulo: 'Ordenes',
        cantidad: '4',
        to: '/misordenes',
        color: 'amber',
        subtitulo: 'Tienes',
        icono: 'mdi-notebook',
        src: 'https://i.ytimg.com/vi/d9H-v5ZIq4U/maxresdefault.jpg'
      }
    ]),
    (this.contadores = [
      {
        modulo: 'Clientes',
        cantidad: '178',
        to: '/catclientes',
        color: 'purple',
        subtitulo: 'Tiene registrados:',
        icono: 'mdi-office-building-cog',
        src: 'https://i.ytimg.com/vi/d9H-v5ZIq4U/maxresdefault.jpg'
      },

      {
        modulo: 'Proyectos',
        cantidad: '6',
        to: '/catproyectos',
        color: 'orange',
        subtitulo: 'Trabaja en tus',
        icono: 'mdi-briefcase',
        src: 'https://i.ytimg.com/vi/d9H-v5ZIq4U/maxresdefault.jpg'
      }
    ])
  },

  methods: {
    mostraract() {
      this.veract = true
      this.vercita = false
      this.veroport = false
      this.verproy = false
      this.vertarea = false
    },
    mostrarcita() {
      this.vercita = true
      this.veract = false
      this.veroport = false
      this.verproy = false
      this.vertarea = false
    },
    mostraroport() {
      this.veroport = true
      this.vercita = false
      this.veract = false
      this.verproy = false
      this.vertarea = false
    },
    mostrarproy() {
      this.verproy = true
      this.vertarea = false
      this.veroport = false
      this.vercita = false
      this.veract = false
    },
    mostrartarea() {
      this.vertarea = true
      this.verproy = false
      this.veroport = false
      this.vercita = false
      this.veract = false
    }
  }
}
</script>

<style scoped>
.numero {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
    Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  color: #34495e;
}
</style>
